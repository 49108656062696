/* Scrollbar Styling for documentSection */
.document-section::-webkit-scrollbar {
    width: 10px;
}

.document-section::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.document-section::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

/* Firefox scrollbar styling */
.document-section {
    scrollbar-width: thin;
    scrollbar-color: #c4c4c4 #f1f1f1;
    border-radius: 10px;
}