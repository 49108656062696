.MuiTab-root.Mui-selected {
  background-color: #001744 !important;
  color: #eef2f6 !important;
}
.toolbarClassName {
  padding-bottom: 0.35em !important;
}

.editorClassName {
  padding: 1em;
  height: auto !important;
  border: 1px solid #f1f1f1;
}

.MuiTabs-indicator {
  display: none;
}

ul {
  margin: 0 !important;
}

.chapter-list ul,
.chapter-sub-list ul {
  padding-left: 8px;
  padding-right: 0px;
  border-left: 1px dashed #ddd;
  margin: 0 !important;
}

.chapter-list {
  padding-left: 8px;
  padding-right: 8px;
}

.chapter-list li {
  list-style: none;
  color: #888;
  font-size: 18px;
  font-weight: normal;
  padding-top: 0px;
  display: grid;
}

.chapter-list li:first-child {
  padding-top: 0px !important;
}

.chapter-sub-list li {
  list-style: none;
  color: #888;
  font-size: 14px;
  font-weight: normal;
}

.chapter-list-button {
  font-family: "Lato";
  background-color: transparent;
  border: none;
  padding: 8px;
  text-align: left;
  min-width: 120px;
}

/*Scrollbar Styling*/
.chapter-list::-webkit-scrollbar {
  width: 10px;
}

.chapter-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chapter-list::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

/* Firefox scrollbar styling */
.chapter-list {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #f1f1f1;
  border-radius: 10px;
}